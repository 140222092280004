<template>
  <div>
    <div class="backgroundImg" style="width: 100%; height: 100vh">
      <div
        style="position: absolute; top: 2%; right: 2%; width: 27%; height: 10%"
      >
        <div @click="goguide()" class="guidebtn"></div>

        <div @click="golms()" class="lmsbtn"></div>

        <div @click="gohomepage()" class="homebtn"></div>
      </div>

      <div
        style="
          position: absolute;
          top: 20%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40%;
        "
      >
        <lottie :options="title"></lottie>
      </div>
      <div style="position: absolute; left: 15%; top: 20%; width: 5%">
        <lottie :options="rock1"></lottie>
      </div>
      <div style="position: absolute; right: 20%; top: 10%; width: 10%">
        <lottie :options="rock2"></lottie>
      </div>
      <div style="position: absolute; left: 12%; top: 53%; width: 10%">
        <lottie :options="rock3"></lottie>
      </div>
      <div
        style="position: absolute; right: 20%; top: 57%; width: 5px; width: 10%"
      >
        <lottie :options="rock4"></lottie>
      </div>
      <div
        v-show="anim == true"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60%;
        "
      >
        <lottie :options="character" @animCreated="handleAnimation"></lottie>
      </div>
      <div
        v-show="anim == false"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60%;
        "
      >
        <lottie
          :options="characterloop"
          @animCreated="handleAnimation"
        ></lottie>
      </div>
      <div
        @click="gologin()"
        v-show="islogin == false"
        class="startbtn"
        style="
          position: absolute;
          bottom: -4%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 800;
        "
      ></div>
      <loginPanel
        style="zoom: 0.8"
        v-show="islogin == true && passwordmodal == false && isStart == true"
      ></loginPanel>

      <div class="container">
        <div style="width: 60vw">
          <v-img src="./assets/Bg_02.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 아이디 확인 창 -->
    <div
      v-show="idmodal == true"
      style="
        zoom: 0.75;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-family: cookiebold;
        z-index: 100;
      "
    >
      <div class="modaltitle">
        <div style="margin-left: 245px; margin-top: 29px">
          <p
            style="
              color: rgb(13, 13, 126);
              font-size: 38px;
              font-weight: bold;
              position: absolute;
              margin-top: 5px;
            "
          >
            내 아이디가 맞나요?
          </p>
          <p
            style="
              color: white;
              font-size: 38px;
              font-weight: bold;
              position: absolute;
            "
          >
            내 아이디가 맞나요?
          </p>
        </div>
      </div>
      <div class="modalcontent">
        <div
          class="idcheckdiv"
          style="
            width: 800px;
            height: 100px;
            background-color: white;
            border-radius: 30px;
            position: absolute;
            margin-top: 100px;
            margin-left: 95px;
            text-align: center;
          "
        >
          <p
            style="
              color: black;
              font-size: 32px;
              font-weight: bold;
              margin-top: 25px;
            "
          >
            {{ userinfo.id }}
          </p>
        </div>
        <div
          style="
            position: absolute;
            margin-top: 250px;
            width: 100%;
            text-align: center;
            color: #5a76ac;
            font-size: 27px;
            font-weight: bold;
          "
        >
          <p>{{ userinfo.school }} 초등학교</p>
          <p>{{ userinfo.name }}</p>
        </div>
        <div style="position: absolute; margin-top: 420px; width: 100%">
          <div style="margin-left: 225px">
            <div @click="idmodalcancel()" class="nobtn"></div>
            <div @click="idmodalconfirm()" class="yesbtn"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 비밀번호 안내 창 -->
    <div
      v-show="passwordcheckmodal == true"
      style="
        zoom: 0.75;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-family: cookiebold;
        z-index: 100;
      "
    >
      <div class="modaltitle">
        <div style="margin-left: 350px; margin-top: 29px">
          <p
            style="
              color: rgb(13, 13, 126);
              font-size: 38px;
              font-weight: bold;
              position: absolute;
              margin-top: 5px;
            "
          >
            안내
          </p>
          <p
            style="
              color: white;
              font-size: 38px;
              font-weight: bold;
              position: absolute;
            "
          >
            안내
          </p>
        </div>
      </div>
      <div class="modalcontent" style="color: #5a76ac">
        <div
          style="
            position: absolute;
            top: 30%;
            text-align: center;
            font-size: 35px;
            left: 11%;
          "
        >
          <p>현재 초기 비밀번호 "000000"으로 설정되어 있습니다.</p>
          <p>비밀번호 변경이 필요합니다.</p>
        </div>

        <div style="position: absolute; margin-top: 470px; width: 100%">
          <div style="margin-left: 225px">
            <div @click="passwordguidecheck()" class="okbtn"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 비밀번호 세팅 창 -->
    <div v-show="passwordmodal == true">
      <passwordPanel id="passwordmodal"></passwordPanel>
    </div>

    <!-- 닉네임 설정 창 -->
    <div
      v-show="nicknamemodal == true"
      style="
        zoom: 0.75;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-family: cookiebold;
        z-index: 100;
      "
    >
      <div class="modaltitle">
        <div style="margin-left: 175px; margin-top: 29px">
          <p
            style="
              color: rgb(13, 13, 126);
              font-size: 38px;
              font-weight: bold;
              position: absolute;
              margin-top: 5px;
            "
          >
            나만의 닉네임을 만들어주세요.
          </p>
          <p
            style="
              color: white;
              font-size: 38px;
              font-weight: bold;
              position: absolute;
            "
          >
            나만의 닉네임을 만들어주세요.
          </p>
        </div>
        <img
          @click="nicknamesetcancel()"
          style="position: absolute; right: 0; top: -3%; cursor: pointer"
          src="./assets/Btn_Close.png"
          alt=""
        />
      </div>
      <div class="modalcontent">
        <input
          v-model="nicknameset"
          maxlength="8"
          autofocus
          spellcheck="false"
          style="
            width: 800px;
            height: 100px;
            background-color: white;
            border-radius: 30px;
            position: absolute;
            margin-top: 100px;
            margin-left: 95px;
            text-align: center;
            outline: none;
            font-size: 30px;
            font-family: cookiebold;
          "
        />

        <div
          style="
            position: absolute;
            margin-top: 250px;
            width: 100%;
            text-align: center;
            color: #5a76ac;
            font-size: 27px;
            font-weight: bold;
            font-family: cookieregular;
          "
        >
          <p>닉네임은 2-8글자, 한글/영어/숫자만 가능합니다.</p>
          <p style="color: #f63b3b">{{ nickerror }}</p>
        </div>
        <div style="position: absolute; margin-top: 470px; width: 100%">
          <div style="margin-left: 225px">
            <div @click="nickconfirm()" class="okbtn"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 닉네임 확인 창 -->
    <div
      v-show="nicknamecheckmodal == true"
      style="
        zoom: 0.75;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-family: cookiebold;
        z-index: 100;
      "
    >
      <div class="modaltitle">
        <div style="margin-left: 175px; margin-top: 29px">
          <p
            style="
              color: rgb(13, 13, 126);
              font-size: 38px;
              font-weight: bold;
              position: absolute;
              margin-top: 5px;
            "
          >
            나만의 닉네임을 확인해주세요.
          </p>
          <p
            style="
              color: white;
              font-size: 38px;
              font-weight: bold;
              position: absolute;
            "
          >
            나만의 닉네임을 확인해주세요.
          </p>
        </div>
      </div>
      <div class="modalcontent">
        <input
          v-model="nicknameset"
          disabled
          spellcheck="false"
          style="
            width: 800px;
            height: 100px;
            background-color: white;
            border-radius: 30px;
            position: absolute;
            margin-top: 100px;
            margin-left: 95px;
            text-align: center;
            outline: none;
            font-size: 30px;
            font-family: cookiebold;
          "
        />

        <div
          style="
            position: absolute;
            margin-top: 250px;
            width: 100%;
            text-align: center;
            color: #5a76ac;
            font-size: 27px;
            font-weight: bold;
            font-family: cookieregular;
          "
        >
          <p>사용 가능한 닉네임입니다. 이 닉네임으로 사용하시겠어요?</p>
        </div>
        <div style="position: absolute; margin-top: 420px; width: 100%">
          <div style="margin-left: 225px">
            <div @click="nicknamecancel()" class="nobtn"></div>
            <div @click="nicknameuse()" class="yesbtn"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 코드 확인 창 -->
    <div
      v-show="codecheckmodal == true"
      style="
        zoom: 0.75;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-family: cookiebold;
        z-index: 100;
      "
    >
      <div class="modaltitle">
        <div style="margin-left: 175px; margin-top: 29px">
          <p
            style="
              color: rgb(13, 13, 126);
              font-size: 38px;
              font-weight: bold;
              position: absolute;
              margin-top: 5px;
            "
          >
            학급 코드를 입력해주세요.
          </p>
          <p
            style="
              color: white;
              font-size: 38px;
              font-weight: bold;
              position: absolute;
            "
          >
            학급 코드를 입력해주세요.
          </p>
        </div>
        <img
          @click="codecheckcancel()"
          style="position: absolute; right: 0; top: -3%; cursor: pointer"
          src="./assets/Btn_Close.png"
          alt=""
        />
      </div>
      <div class="modalcontent">
        <input
          v-model="whalecode"
          spellcheck="false"
          autofocus
          placeholder="학급 코드 입력"
          style="
            width: 800px;
            height: 100px;
            background-color: white;
            border-radius: 30px;
            position: absolute;
            margin-top: 100px;
            margin-left: 95px;
            text-align: center;
            outline: none;
            font-size: 30px;
            font-family: cookiebold;
          "
        />

        <div
          style="
            position: absolute;
            margin-top: 250px;
            width: 100%;
            text-align: center;
            color: #5a76ac;
            font-size: 27px;
            font-weight: bold;
            font-family: cookieregular;
          "
        >
          <p style="font-size: 29px">
            {{ codechecktext }}
          </p>
          <p style="color: #f63b3b">{{ codeerrortext }}</p>
        </div>
        <div style="position: absolute; margin-top: 420px; width: 100%">
          <div>
            <div
              style="margin-top: 50px"
              @click="codecheck()"
              class="okbtn"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 코드 입력 후 안내창 -->
    <div
      v-show="guideModal == true"
      style="
        zoom: 0.75;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-family: cookiebold;
        z-index: 100;
      "
    >
      <div class="modaltitle">
        <div style="margin-left: 350px; margin-top: 29px">
          <p
            style="
              color: rgb(13, 13, 126);
              font-size: 38px;
              font-weight: bold;
              position: absolute;
              margin-top: 5px;
            "
          >
            안내
          </p>
          <p
            style="
              color: white;
              font-size: 38px;
              font-weight: bold;
              position: absolute;
            "
          >
            안내
          </p>
        </div>
      </div>
      <div class="modalcontent">
        <div
          style="
            position: absolute;
            margin-top: 150px;
            width: 100%;
            text-align: center;
            color: #5a76ac;
            font-size: 27px;
            font-weight: bold;
            font-family: cookieregular;
          "
        >
          <p style="font-size: 32px">
            {{ userinfo.schoolName }}초등학교 {{ userinfo.grade }}학년
            {{ userinfo.classNum }}반 학생입니다.
          </p>
          <p style="font-size: 32px">
            가입을 계속하려면, 아래 OK 버튼을 눌러주세요.
          </p>
        </div>
        <div style="position: absolute; margin-top: 420px; width: 100%">
          <div>
            <div
              style="margin-top: 50px"
              @click="guideok()"
              class="okbtn"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="errormodal == true">
      <errorModal :errortext="errortext" :flag="flag"></errorModal>
    </div>

    <v-dialog persistent v-model="whaleloginflag" width="500">
      <div style="width: 500px; height: 70px; background-color: white">
        <p
          @click="whalecancel()"
          class="noselect"
          style="
            font-size: 30px;
            float: right;
            margin-right: 15px;
            margin-top: 5px;
            cursor: pointer;
          "
        >
          ✖
        </p>
      </div>
      <div
        style="
          width: 500px;
          height: 750px;
          background-color: white;
          overflow-x: hidden;
        "
      >
        <iframe
          style="width: 500px; height: 700px"
          :src="whaleurl"
          frameborder="0"
        ></iframe>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import * as rock1 from "./assets/Ame_Rock_01.json";
import * as rock2 from "./assets/Ame_Rock_02.json";
import * as rock3 from "./assets/Ame_Rock_03.json";
import * as rock4 from "./assets/Ame_Rock_04.json";
import * as character from "./assets/Ame_Character.json";
import * as characterloop from "./assets/Ame_Character_Loop.json";
import * as title from "./assets/Ame_Title.json";
import { v4 as uuidv4 } from "uuid";
import {
  usernicknamecheck,
  usernicknamechange,
  setuserfirstlogin,
} from "./api.js";

import loginPanel from "./panel/LoginPanel.vue";
import passwordPanel from "./panel/passwordPanel.vue";
import errorModal from "./modal/errorModal.vue";
import { getSaveCookie, deleteCookie } from "./store/cookie";
import axios from "axios";

const VUE_APP_CONNECT = "https://test-server.argong.ai";

export default {
  created() {
    this.whaleurl = `${process.env.VUE_APP_CONNECT}/users/whale-ad/webgl`;
    const cool = getSaveCookie("cool");
    deleteCookie("cool");

    this.character.animationData = character;

    setTimeout(() => {
      this.isStart = true;
    }, 1000);

    setTimeout(() => {
      this.anim = false;
    }, 2000);

    window.addEventListener("loginChallenge", this.loginChallenge);
    window.addEventListener("error", this.errorchange);
    window.addEventListener("message", function (event) {
      if (event.data.type) {
        if (event.data.type == "loadComplete") {
          const loadCompleteEvent = new CustomEvent("loadComplete", {
            detail: true,
          });

          window.dispatchEvent(loadCompleteEvent);
        }
      }
    });
    window.addEventListener("nicknameChange", this.handlenick);
    window.addEventListener("codeCheck", this.handleCodeCheck);
    window.addEventListener("whalego", this.whalelogin);
    window.addEventListener("whalelogincomplete", this.handleWhaleSuccess);

    window.addEventListener("hiddencommand", this.hiddencommand);

    if (cool === "true") {
      this.islogin = true;
      this.isStart = true;
      this.iscool = true;
      this.coollogin();
    }
  },
  beforeDestroy() {
    window.removeEventListener("loginChallenge", this.loginChallenge);
    window.removeEventListener("error", this.errorchange);
  },
  data() {
    return {
      whaleurl: "",
      iscool: false,
      whalecode: "",
      isStart: false,
      islogin: false,
      iseyeopen: false,
      anim: true,
      userinfo: {
        school: "",
        name: "",
        nickName: "",
        id: "",
      },
      id: "",
      pw: "",
      token: "",
      rock1: {
        animationData: rock1,
      },
      rock2: {
        animationData: rock2,
      },
      rock3: {
        animationData: rock3,
      },
      rock4: {
        animationData: rock4,
      },
      character: {
        animationData: character,
        loop: false,
      },
      characterloop: {
        animationData: characterloop,
      },
      title: {
        animationData: title,
        loop: false,
      },
      isAnimation1Playing: true,
      idmodal: false,
      passwordmodal: false,
      errormodal: false,
      errortext: "",
      flag: "",
      detail: "",
      nicknamemodal: false,
      nicknameset: "",
      nickerror: "",
      nicknamecheckmodal: false,
      passwordcheckmodal: false,
      codecheckmodal: false,
      codechecktext: "선생님이 알려주신 학급 코드를 정확하게 입력해주세요.",
      codeerrortext: "",
      waitevent: "",
      whaleloginflag: false,
      iswhale: false,
      guideModal: false,
    };
  },
  components: {
    lottie: Lottie,
    loginPanel,
    passwordPanel,
    errorModal,
  },
  methods: {
    handleAnimation: function (animation) {
      animation.addEventListener("complete", () => {
        if (this.isAnimation1Playing) {
          this.character = {
            ...this.character, // 기존 옵션을 유지하면서
            animationData: this.characterloop, // 애니메이션 데이터만 교체
          };
        } else {
          this.character = {
            ...this.character,
            animationData: character,
          };
        }
        // 애니메이션 상태 토글
        this.isAnimation1Playing = !this.isAnimation1Playing;
      });
    },
    hiddencommand() {
      this.islogin = true;
    },
    loginChallenge(event) {
      const res = event.detail;
      this.userinfo = res.userinfo;
      this.token = res.token;

      if (res.first) {
        this.idmodal = true;

        document.getElementsByClassName("backgroundImg")[0].style.filter =
          "brightness(50%)";
      } else if (res.userinfo.pw == "000000" || res.userinfo.pw == "111111") {
        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);
        document.getElementsByClassName("backgroundImg")[0].style.filter =
          "brightness(40%)";
        this.passwordcheckmodal = true;
      } else {
        // 게임 시작 이벤트 발송
        const uid = uuidv4();

        const event = new CustomEvent("sessionStorageChange", {
          detail: { key: "uid", value: uid },
        });

        const loginEvent = new CustomEvent("login", {
          detail: res.token,
        });

        window.dispatchEvent(event);
        window.dispatchEvent(loginEvent);
      }
    },
    async maintenance() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_CONNECT}/server/maintenance`,
          { credentials: true }
        );

        const data = res.data.data;

        return data;
      } catch {
        return true;
      }
    },
    async gologin() {
      // const maintenance = await this.maintenance();
      // if (maintenance) {
      //   const event = new CustomEvent("error", {
      //     detail: { errortext: "서버 점검 중입니다." },
      //   });
      //   window.dispatchEvent(event);

      //   return;
      // }

      this.islogin = true;
    },
    eye() {
      this.iseyeopen = !this.iseyeopen;
    },
    gohomepage() {
      window.open("https://www.argong.ai");
    },
    golms() {
      window.open("https://manager.argong.ai");
    },
    goguide() {
      window.open("https://bit.ly/3PkiTh3");
    },
    idmodalcancel() {
      document.getElementsByClassName("backgroundImg")[0].style.filter =
        "brightness(100%)";

      this.idmodal = false;
      this.userinfo = "";
    },
    idmodalconfirm() {
      this.idmodal = false;
      setuserfirstlogin(this.token);
      if (this.userinfo.pw == "000000" || this.userinfo.pw == "111111") {
        document.getElementsByClassName("backgroundImg")[0].style.filter =
          "brightness(40%)";
        this.passwordcheckmodal = true;

        return;
      } else if (!this.userinfo.nickName || this.userinfo.nickName === "-") {
        // 닉변 이벤트
        const user = this.userinfo;

        const event = new CustomEvent("nicknameChange", {
          detail: {
            key: "nicknameChange",
            first: false,
            token: this.token,
            userinfo: {
              id: user.id,
              school: user.schoolName,
              name: user.name,
              nickName: user.nickName,
            },
          },
        });

        window.dispatchEvent(event);

        return;
      } else {
        const uid = uuidv4();

        const event = new CustomEvent("sessionStorageChange", {
          detail: { key: "uid", value: uid },
        });

        const loginEvent = new CustomEvent("login", {
          detail: this.token,
        });

        const loadflag = sessionStorage.getItem("load");
        if (!loadflag) {
          //기다렸다가 전송

          const loadingEvent = new CustomEvent("loading", {
            detail: true,
          });

          window.dispatchEvent(loadingEvent);

          const interval = setInterval(() => {
            const loadflag = sessionStorage.getItem("load");
            if (loadflag) {
              clearInterval(interval);
              window.dispatchEvent(event);
              window.dispatchEvent(loginEvent);
            }
          }, 1000);
        } else {
          window.dispatchEvent(event);
          window.dispatchEvent(loginEvent);
        }
      }
      //this.passwordmodal = true;
    },
    passwordguidecheck() {
      this.passwordcheckmodal = false;
      this.passwordmodal = true;
    },
    errorchange(event) {
      const res = event.detail;
      if (res.flag == "initconfirm") {
        this.errormodal = false;
        this.idmodal = false;
        this.passwordmodal = false;
        this.islogin = false;
        this.token = "";
        document.getElementsByClassName("backgroundImg")[0].style.filter =
          "brightness(100%)";

        document.getElementById("passwordmodal").style.filter =
          "brightness(100%)";
        return;
      }

      if (!res.errortext) {
        this.errormodal = false;
        document.getElementsByClassName("backgroundImg")[0].style.filter =
          "brightness(100%)";

        document.getElementById("passwordmodal").style.filter =
          "brightness(100%)";
      } else {
        this.errormodal = true;
        this.errortext = res.errortext;
        this.flag = res.flag;
        document.getElementsByClassName("backgroundImg")[0].style.filter =
          "brightness(50%)";

        document.getElementById("passwordmodal").style.filter =
          "brightness(50%)";
      }
    },
    handlenick(event) {
      this.detail = event.detail;
      this.nicknamemodal = true;
      document.getElementsByClassName("backgroundImg")[0].style.filter =
        "brightness(50%)";

      document.getElementById("passwordmodal").style.filter = "brightness(50%)";
    },
    async nickconfirm() {
      const nickname = this.nicknameset;

      if (nickname.length < 2) {
        this.nickerror = "닉네임을 2~8 글자로 설정해주세요.";
        return;
      }

      const type = "login";
      const token = this.detail.token;
      const data = { nickname, type };

      const response = await usernicknamecheck(token, data);

      const res = response.data.data;
      if (res == -1) {
        this.nickerror = "닉네임에 비속어를 사용할 수 없습니다.";
        return;
      }

      if (res == -2) {
        this.nickerror = "이미 존재하는 닉네임입니다.";
        return;
      }

      if (res == 1) {
        this.nicknamecheckmodal = true;
        this.nicknamemodal = false;
      }
    },
    nicknamecancel() {
      this.nicknamecheckmodal = false;
      this.nicknamemodal = true;
    },
    async nicknameuse() {
      const token = this.detail.token;
      const nickname = this.nicknameset;
      const type = "login";
      const data = {
        nickname,
        type,
      };

      let response = "";

      response = await usernicknamechange(token, data);

      const res = response.data.data;
      if (!res) {
        //게임 스타트
        window.dispatchEvent(
          new CustomEvent("loading", {
            detail: true,
          })
        );

        let loginEvent = new CustomEvent("loginChallenge", {
          detail: {
            key: "logininfo",
            first: false,
            token: this.detail.token,
            userinfo: {},
          },
        });

        const loadflag = sessionStorage.getItem("load");
        if (!loadflag) {
          //기다렸다가 전송

          const interval = setInterval(() => {
            const loadflag = sessionStorage.getItem("load");
            if (loadflag) {
              clearInterval(interval);
              //sessionStorage.removeItem("load");
              window.dispatchEvent(
                new CustomEvent("loading", {
                  detail: false,
                })
              );
              window.dispatchEvent(loginEvent);
            }
          }, 1000);
        } else {
          //sessionStorage.removeItem("load");
          window.dispatchEvent(
            new CustomEvent("loading", {
              detail: false,
            })
          );
          window.dispatchEvent(loginEvent);
        }

        this.detail = "";
        return;
      }
    },
    nicknamesetcancel() {
      this.detail = {};
      document.getElementsByClassName("backgroundImg")[0].style.filter =
        "brightness(100%)";
      this.nicknamemodal = false;
    },
    handleCodeCheck() {
      document.getElementsByClassName("backgroundImg")[0].style.filter =
        "brightness(40%)";
      this.codecheckmodal = true;
    },
    async codecheck() {
      let token = getSaveCookie("info2");
      if (!token) {
        token = sessionStorage.getItem("info2");
      }

      const data = {
        code: this.whalecode,
      };
      const res = await axios.post(
        `${process.env.VUE_APP_CONNECT}/users/code/signup`,
        data,
        {
          credentials: true,
          headers: {
            Authorization: token,
          },
        }
      );

      const result = res.data.data;

      if (result !== "student") {
        this.codechecktext =
          "선생님이 알려주신 학급 코드를 정확하게 입력해주세요.";
        this.codeerrortext = "사용할 수 없는 코드입니다.";
        return;
      }

      // 코드 성공시
      if (result === "student") {
        // 토큰 재요청
        this.tokenRefresh();

        // 안내 모달 출력
        this.guideModal = true;

        // 모달 제거, 배경 brightness 100%, 코드 관련 데이터 초기화
        document.getElementsByClassName("backgroundImg")[0].style.filter =
          "brightness(100%)";
        this.codecheckmodal = false;
        this.codeerrortext = "";

        await axios.post(
          `${VUE_APP_CONNECT}/users/code/levelup`,
          {
            level: "student",
          },
          {
            credentials: true,
            headers: {
              Authorization: token,
            },
          }
        );
      } else {
        if (result === -1) {
          this.codechecktext =
            "선생님이 알려주신 학급 코드를 정확하게 입력해주세요.";
          this.codeerrortext = "존재하지 않는 코드입니다.";
          return;
        } else {
          this.codechecktext = "선생님께 문의해주세요.";
          this.codeerrortext = "가입할 수 있는 인원이 초과된 코드입니다.";
          return;
        }
        // 코드 실패시 -1 -> 없는 코드, -100 -> 학생 꽉참
        return;
      }
    },
    handleWhaleSuccess() {
      this.whaleloginflag = false;
    },
    async gowhalelogin() {},
    coollogin() {
      this.waitevent = setInterval(async () => {
        const token = getSaveCookie("info2");

        if (!token) {
          return;
        } else {
          // 토큰 존재, 코드 입력화면으로 ㄱㄱ
          clearInterval(this.waitevent);
          this.token = token;
          //sessionStorage.clear();

          const res = await axios.get(`${VUE_APP_CONNECT}/users`, {
            credentials: true,
            headers: {
              Authorization: this.token,
            },
          });

          this.userinfo = res.data.data;

          const { schoolCode, schoolLevel } = res.data.data;

          if (schoolCode === "-" || schoolLevel === "-") {
            this.whaleloginflag = false;
            this.handleCodeCheck();
          } else {
            // 로그인 진행
            this.whaleloginflag = false;

            if (!res.data.data.nickName || res.data.data.nickName === "-") {
              this.guideModal = true;
              return;
            }

            window.dispatchEvent(
              new CustomEvent("loading", {
                detail: true,
              })
            );

            const loadflag = sessionStorage.getItem("load");
            if (!loadflag) {
              //기다렸다가 전송

              const interval = setInterval(() => {
                const loadflag = sessionStorage.getItem("load");
                if (loadflag) {
                  clearInterval(interval);
                  //sessionStorage.removeItem("load");
                  window.dispatchEvent(
                    new CustomEvent("loading", {
                      detail: false,
                    })
                  );

                  this.$store.state.isLogin = true;
                  window.dispatchEvent(
                    new CustomEvent("login", {
                      detail: token,
                    })
                  );
                }
              }, 1000);
            } else {
              //sessionStorage.removeItem("load");
              window.dispatchEvent(
                new CustomEvent("loading", {
                  detail: false,
                })
              );

              this.$store.state.isLogin = true;
              window.dispatchEvent(
                new CustomEvent("login", {
                  detail: token,
                })
              );
            }
          }

          return;
        }
      }, 300);
    },
    whalelogin() {
      this.iswhale = true;
      this.whaleloginflag = true;
      this.waitevent = setInterval(async () => {
        const token = sessionStorage.getItem("info2");

        if (!token) {
          return;
        } else {
          // 토큰 존재, 코드 입력화면으로 ㄱㄱ
          clearInterval(this.waitevent);
          this.token = token;
          //sessionStorage.clear();

          const res = await axios.get(`${VUE_APP_CONNECT}/users`, {
            credentials: true,
            headers: {
              Authorization: this.token,
            },
          });

          this.userinfo = res.data.data;

          const { schoolCode, schoolLevel } = res.data.data;

          if (
            !schoolCode ||
            schoolCode === "-" ||
            !schoolLevel ||
            schoolLevel === "-"
          ) {
            this.whaleloginflag = false;
            this.handleCodeCheck();
          } else {
            // 로그인 진행
            this.whaleloginflag = false;

            if (!res.data.data.nickName || res.data.data.nickName === "-") {
              this.guideModal = true;
              return;
            }

            window.dispatchEvent(
              new CustomEvent("loading", {
                detail: true,
              })
            );

            const loadflag = sessionStorage.getItem("load");
            if (!loadflag) {
              //기다렸다가 전송

              const interval = setInterval(() => {
                const loadflag = sessionStorage.getItem("load");
                if (loadflag) {
                  clearInterval(interval);
                  //sessionStorage.removeItem("load");
                  window.dispatchEvent(
                    new CustomEvent("loading", {
                      detail: false,
                    })
                  );

                  this.$store.state.isLogin = true;
                  window.dispatchEvent(
                    new CustomEvent("login", {
                      detail: token,
                    })
                  );
                }
              }, 1000);
            } else {
              //sessionStorage.removeItem("load");
              window.dispatchEvent(
                new CustomEvent("loading", {
                  detail: false,
                })
              );

              this.$store.state.isLogin = true;
              window.dispatchEvent(
                new CustomEvent("login", {
                  detail: token,
                })
              );
            }
          }

          return;
        }
      }, 300);
    },
    whalecancel() {
      this.iswhale = false;
      this.whaleloginflag = false;
      clearInterval(this.waitevent);
    },
    async tokenRefresh() {
      const res = await axios.post(
        `${VUE_APP_CONNECT}/users/token/whale/refresh`,
        { id: this.userinfo.id },
        { credentials: true, headers: { Authorization: this.token } }
      );

      const res2 = await axios.get(`${VUE_APP_CONNECT}/users`, {
        credentials: true,
        headers: { Authorization: res.data.data },
      });

      this.token = res.data.data;
      this.userinfo = res2.data.data;

      return;
    },
    codecheckcancel() {
      document.getElementsByClassName("backgroundImg")[0].style.filter =
        "brightness(100%)";
      this.codecheckmodal = false;
    },
    guideok() {
      // 닉네임 설정
      if (!this.userinfo.nickName || this.userinfo.nickName === "-") {
        this.guideModal = false;
        const user = this.userinfo;

        const event = new CustomEvent("nicknameChange", {
          detail: {
            key: "nicknameChange",
            first: false,
            token: this.token,
            userinfo: {
              id: user.id,
              school: user.schoolName,
              name: user.name,
              nickName: user.nickName,
            },
          },
        });

        window.dispatchEvent(event);
        return;
      } else {
        window.dispatchEvent(
          new CustomEvent("login", {
            detail: this.token,
          })
        );
        return;
      }
    },
  },
  watch: {
    nicknameset(value) {
      const regex = /^[a-zA-Z가-힣0-9]*$/;

      if (!regex.test(value)) {
        this.nicknameset = value.slice(0, -1);
      }

      if (value.length > 8) {
        this.nicknameset = value.slice(0, 8);
      }
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: cookiebold;
  src: url("./assets/font/CookieRun Bold.otf");
}

@font-face {
  font-family: cookieregular;
  src: url("./assets/font/CookieRun Regular.otf");
}

.backgroundImg {
  background-image: url("./assets/Bg_01.png");
  background-size: cover;
  /* filter: brightness(0.5); */
}

.back2 {
  background-image: url("./assets/Bg_02.png");
}

.container {
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: flex-end; /* 세로 하단 정렬 */
  height: 100vh; /* 컨테이너의 높이, 필요에 따라 조정 */
  border: 1px solid #000; /* 경계선은 시각적 확인을 위함 */
  margin-left: 55px;
  border: none;
}

.startbtn {
  width: 21%;
  height: 15%;
  background-image: url("./assets/Btn_Start.png");
  background-size: 100%;
  border-radius: 80px;
  cursor: pointer;
  min-width: 400px;
  min-height: 150px;
}

.startbtn:hover {
  filter: brightness(0.7);
}

.logindiv {
  background-image: url("./assets/Login_Popup.png");
  background-size: 100%;
}

.inputcontainer {
  width: 90%;
  height: 25%;
  background-image: url("./assets/Login_Input.png");
  background-size: 100%;
  background-position: center;
  border-style: none;
  padding: 25px;
  font-size: 17px;
  outline: none;
}

.idicon {
  background-image: url("./assets/idicon.png");
  background-size: cover;
  width: 4%;
  height: 28.3%;
  left: 2%;
  top: 32%;
  position: absolute;
}

.pwicon {
  background-image: url("./assets/pwicon.png");
  background-size: cover;
  width: 4.2%;
  height: 30.2%;
  left: 2%;
  top: 32%;
  position: absolute;
}

.pwiconeyeclose {
  background-image: url("./assets/eyeclose.png");
  background-size: cover;
  width: 5%;
  height: 30.2%;
  right: 2%;
  top: 32%;
  position: absolute;
  cursor: pointer;
}

.pwiconeyeopen {
  background-image: url("./assets/eyeopen.png");
  background-size: cover;
  width: 5%;
  height: 30.2%;
  right: 2%;
  top: 32%;
  position: absolute;
  cursor: pointer;
}

.modaltitle {
  background-image: url("./assets/modaltitlepanel.png");
  background-size: 100%;
  width: 800px;
  height: 160px;
  position: absolute;
  margin-top: -50px;
  margin-left: 95px;
}

.modalcontent {
  background-image: url("./assets/modalPopup.png");
  background-size: 100%;
  width: 900px;
  height: 570px;
  min-width: 1000px;
}

.idcheckdiv {
  background-image: url("./assets/idicon.png");
  background-position-x: 3%;
  background-position-y: 45%;
  background-size: 7%;
}

.nobtn {
  background-image: url("./assets/Btn_No.png");
  background-size: 100%;
  width: 250px;
  height: 95px;
  cursor: pointer;
  float: left;
  margin-right: 40px;
  border-radius: 40px;
}

.yesbtn {
  background-image: url("./assets/Btn_Yes.png");
  background-size: 100%;
  width: 250px;
  height: 95px;
  cursor: pointer;
  float: left;
}

.nobtn:hover {
  filter: brightness(0.8);
}

.yesbtn:hover {
  filter: brightness(0.8);
}

.homebtn {
  width: 22%;
  height: 130%;
  min-height: 200px;
  background-image: url("./assets/Btn_Home.png");
  background-size: 100%;
  float: right;
  cursor: pointer;
}

.lmsbtn {
  width: 22%;
  height: 130%;
  min-height: 200px;
  background-image: url("./assets/Btn_LMS.png");
  background-size: 100%;
  float: right;
  cursor: pointer;
  margin-left: 10px;
}

.guidebtn {
  width: 22%;
  height: 130%;
  min-height: 200px;
  background-image: url("./assets/Btn_Guide.png");
  background-size: 100%;
  float: right;
  cursor: pointer;
  margin-left: 10px;
}

.homebtn:hover {
  filter: brightness(0.7);
}

.lmsbtn:hover {
  filter: brightness(0.7);
}

.guidebtn:hover {
  filter: brightness(0.7);
}

.okbtn {
  background-image: url("./assets/Btn_OK.png");
  background-size: 100%;
  width: 250px;
  height: 95px;
  cursor: pointer;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.okbtn:hover {
  filter: brightness(0.7);
}
</style>