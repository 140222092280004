<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      background-color: rgb(1, 1, 1, 0.5);
      position: absolute;
    "
  >
    egeg
  </div>
</template>

<script>
import idcheckmodal from "../modal/idCheckModal.vue";

export default {
  components: {
    idcheckmodal,
  },
};
</script>

<style>
</style>