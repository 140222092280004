import axios from 'axios'

const userlogin = async (data) => {
  return await axios
    .post(`${process.env.VUE_APP_CONNECT}/users/login`, data, {
      credentials: true
    })
    .catch((err) => {
      return err.response
    })
}

const userinfo = async (token) => {
  return await axios.get(`${process.env.VUE_APP_CONNECT}/users`, {
    headers: {
      Authorization: token
    }
  })
}

const userfirstlogin = async (token) => {
  return await axios.get(`${process.env.VUE_APP_CONNECT}/users/first-login`, {
    headers: {
      Authorization: token
    }
  })
}

const userwearing = async (token) => {
  return await axios.get(`${process.env.VUE_APP_CONNECT}/games/wearing`, {
    headers: {
      Authorization: token
    }
  })
}

const userpasswordchange = async (token, data) => {
  return await axios.post(
    `${process.env.VUE_APP_CONNECT}/users/password/webgl`,
    data,
    {
      headers: {
        Authorization: token
      }
    }
  )
}

const usernicknamecheck = async (token, data) => {
  return await axios.post(
    `${process.env.VUE_APP_CONNECT}/users/nickname/check`,
    data,
    {
      headers: {
        Authorization: token
      }
    }
  )
}

const usernicknamechange = async (token, data) => {
  return await axios.put(
    `${process.env.VUE_APP_CONNECT}/users/nickname`,
    data,
    {
      headers: {
        Authorization: token
      }
    }
  )
}

const setuserfirstlogin = async (token) => {
  return await axios.get(`${process.env.VUE_APP_CONNECT}/users/firstloginset`, {
    headers: {
      Authorization: token
    }
  })
}

export {
  userlogin,
  userinfo,
  userfirstlogin,
  userwearing,
  userpasswordchange,
  usernicknamecheck,
  usernicknamechange,
  setuserfirstlogin
}
