import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComponent from '../HomeComponent.vue'
import whaleLoginComponent from '../panel/whaleLoginComponent.vue'
import coolSchoolLoginComponent from '../panel/coolSchoolLoginComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent
  },
  {
    path: '/whale/:code',
    name: 'whale',
    component: whaleLoginComponent
  },
  {
    path: '/coolschool/:key',
    name: 'coolschool',
    component: coolSchoolLoginComponent
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
