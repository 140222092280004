<template>
  <div class="logindiv">
    <input
      @keyup.enter="login()"
      v-model="id"
      autofocus
      type="text"
      placeholder="아이디"
      class="idinput"
    />
    <div>
      <input
        @keyup.enter="login()"
        v-show="iseyeopen == false"
        v-model="pw"
        type="password"
        placeholder="비밀번호"
        class="pwinput"
      />
      <input
        @keyup.enter="login()"
        v-show="iseyeopen == true"
        type="text"
        v-model="pw"
        placeholder="Password"
        class="pwinput"
      />
      <img
        @click="eyeopen()"
        v-show="iseyeopen == false"
        style="
          width: 55px;
          float: right;
          margin-top: 200px;
          position: absolute;
          margin-left: 910px;
          cursor: pointer;
        "
        src="../assets/eyeclose.png"
        alt=""
      />
      <img
        @click="eyeopen()"
        v-show="iseyeopen == true"
        style="
          width: 55px;
          float: right;
          margin-top: 200px;
          position: absolute;
          margin-left: 910px;
          cursor: pointer;
        "
        src="../assets/eyeopen.png"
        alt=""
      />
    </div>
    <div @click="login()" class="loginbtn"></div>
    <div @click="whalelogin()" class="whale"></div>
    <div @click="coollogin()" class="coolschool">
      <p
        style="
          position: absolute;
          margin-top: 13px;
          margin-left: 60px;
          font-family: notosans;
          font-size: 18px;
        "
      >
        Sign in with CoolSchool
      </p>
    </div>
    <v-dialog width="1000" v-model="maintenanceimg">
      <div
        @click="
          {
            maintenanceimg = false;
          }
        "
        class="maintenanceimg"
      ></div>
    </v-dialog>
  </div>
</template>

<script>
import { userlogin, userinfo, userwearing, userfirstlogin } from "../api.js";

export default {
  data() {
    return {
      id: "",
      pw: "",
      iseyeopen: false,
      maintenanceimg: false,
    };
  },
  methods: {
    async maintenance() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_CONNECT}/server/maintenance`,
          { credentials: true }
        );

        const data = res.data.data;

        return data;
      } catch {
        return true;
      }
    },
    async whalelogin() {
      window.dispatchEvent(
        new CustomEvent("whalego", {
          detail: true,
        })
      );
    },
    async coollogin() {
      window.location.href = `https://coolauth.coolschool.co.kr/realms/coolschool/protocol/openid-connect/auth?response_type=code&client_id=argong&schoolCode=7430240&redirect_uri=${process.env.VUE_APP_CONNECT}/users/auth/coolschool/webgl`;
    },
    getUserAgentDetails() {
      const ua = navigator.userAgent;
      let browserName = "Unknown Browser";
      let browserVersion = "Unknown Version";
      let os = "Unknown OS";

      // OS Detection
      if (ua.indexOf("Win") != -1) os = "Windows";
      else if (ua.indexOf("Mac") != -1) os = "MacOS";
      else if (ua.indexOf("X11") != -1) os = "UNIX";
      else if (ua.indexOf("Linux") != -1) os = "Linux";
      else if (ua.indexOf("Android") != -1) os = "Android";
      else if (ua.indexOf("like Mac") != -1) os = "iOS";

      // Browser Detection
      if (ua.indexOf("Firefox") != -1) {
        browserName = "Firefox";
        browserVersion = ua.substring(ua.indexOf("Firefox") + 8);
      } else if (ua.indexOf("Opera") != -1 || ua.indexOf("OPR") != -1) {
        browserName = "Opera";
        browserVersion =
          ua.substring(ua.indexOf("Opera") + 6) ||
          ua.substring(ua.indexOf("OPR") + 4);
      } else if (ua.indexOf("Chrome") != -1) {
        browserName = "Chrome";
        browserVersion = ua.substring(ua.indexOf("Chrome") + 7).split(" ")[0];
      } else if (ua.indexOf("Safari") != -1) {
        browserName = "Safari";
        browserVersion = ua.substring(ua.indexOf("Safari") + 7).split(" ")[0];
      } else if (ua.indexOf("MSIE") != -1 || ua.indexOf("Trident") != -1) {
        browserName = "Internet Explorer";
        browserVersion =
          ua.substring(ua.indexOf("MSIE") + 5) ||
          ua.substring(ua.indexOf("rv:") + 3);
      }

      // Format: OS_Browser Name_Browser Version
      return {
        os,
        browserName,
        browserVersion,
      };
    },
    eyeopen() {
      this.iseyeopen = !this.iseyeopen;
    },
    inputdisabled() {
      const target1 = document.getElementsByClassName("idinput");
      const target2 = document.getElementsByClassName("pwinput");

      target1[0].disabled = true;
      for (let i = 0; i < target2.length; i++) {
        target2[i].disabled = true;
      }
    },
    inputenabled() {
      const target1 = document.getElementsByClassName("idinput");
      const target2 = document.getElementsByClassName("pwinput");

      target1[0].disabled = false;
      for (let i = 0; i < target2.length; i++) {
        target2[i].disabled = false;
      }
    },
    async login() {
      const id = this.id;
      const password = this.pw;

      this.inputdisabled();

      if (!id || !password) {
        const event = new CustomEvent("error", {
          detail: { errortext: "로그인 정보를 입력해주세요." },
        });
        window.dispatchEvent(event);
        this.inputenabled();
        return;
      }

      const osInfo = this.getUserAgentDetails();
      const { os, browserName, browserVersion } = osInfo;
      const version = browserVersion.substring(0, 3);
      const osdata = `WebGL_${os}_${browserName}_${version}`;

      const data = { id, password, os: osdata };

      const loadingEvent = new CustomEvent("loading", {
        detail: true,
      });

      window.dispatchEvent(loadingEvent);

      const res = await userlogin(data);

      const token = res.data.data;
      if (token == -4) {
        // const event = new CustomEvent("error", {
        //   detail: { errortext: "서버 점검 중입니다." },
        // });
        this.maintenanceimg = true;

        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);
        //window.dispatchEvent(event);
        this.inputenabled();
        return;
      }

      if (token == -1) {
        const event = new CustomEvent("error", {
          detail: { errortext: "아이디 또는 비밀번호를 잘못 입력했습니다." },
        });
        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);
        window.dispatchEvent(event);
        this.inputenabled();
        return;
      }

      if (token == -2) {
        const event = new CustomEvent("error", {
          detail: { errortext: "아이디 또는 비밀번호를 잘못 입력했습니다." },
        });
        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);
        window.dispatchEvent(event);
        this.inputenabled();
        return;
      }

      if (token == -3) {
        const event = new CustomEvent("error", {
          detail: { errortext: "계약이 만료된 계정입니다." },
        });
        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);
        window.dispatchEvent(event);
        this.inputenabled();
        return;
      }

      if (token.toString().length < 10) {
        const event = new CustomEvent("error", {
          detail: { errortext: "로그인 중 오류가 발생하였습니다." },
        });
        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);
        window.dispatchEvent(event);
        this.inputenabled();
        return;
      }

      const info = await userinfo(token);
      const first = await userfirstlogin(token);

      if (first.data.data) {
        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);
      }

      const wearing = await userwearing(token);
      const user = info.data.data;

      // sessionStorage.setItem("uid", uid);

      const loginevent = new CustomEvent("loginChallenge", {
        detail: {
          key: "logininfo",
          first: first.data.data,
          token,
          userinfo: {
            id: user.id,
            pw: this.pw,
            school: user.schoolName,
            name: user.name,
            nickName: user.nickName,
          },
        },
      });

      if (first.data.data) {
        window.dispatchEvent(loginevent);
      } else if (this.pw === "000000" || this.pw === "111111") {
        window.dispatchEvent(loginevent);
      } else if (!user.nickName) {
        //닉네임 설정 팝업으로 이동
        //닉네임 설정 이후 즉시 webgl로 이동
        const nicknameEvent = new CustomEvent("nicknameChange", {
          detail: {
            key: "nicknameChange",
            first: first.data.data,
            token,
            userinfo: {
              id: user.id,
              school: user.schoolName,
              name: user.name,
              nickName: user.nickName,
            },
          },
        });

        const loadingEvent = new CustomEvent("loading", {
          detail: false,
        });

        window.dispatchEvent(loadingEvent);

        window.dispatchEvent(nicknameEvent);
      } else {
        const loadflag = sessionStorage.getItem("load");
        if (!loadflag) {
          //기다렸다가 전송

          const interval = setInterval(() => {
            const loadflag = sessionStorage.getItem("load");
            if (loadflag) {
              clearInterval(interval);
              //sessionStorage.removeItem("load");
              window.dispatchEvent(loginevent);
            }
          }, 1000);
        } else {
          //sessionStorage.removeItem("load");
          window.dispatchEvent(loginevent);
        }
      }
      this.id = "";
      this.pw = "";
      this.inputenabled();
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: notosans;
  src: url("../assets/font/NotoSansKR-Bold.otf");
}

.logindiv {
  position: absolute;
  background-image: url("../assets/Login_Popup.png");
  width: 1100px;
  height: 600px;
  background-size: 100%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

input {
  background-color: #f4fbff;
  width: 80%;
  font-size: 120%;
  border-radius: 30px;
  border-bottom: 9px solid #607ccd;
  outline: none;
  height: 14%;
  padding-left: 80px;
  font-family: notosans;
}

.inputcontainer {
  background-image: url("../assets/Login_Input.png");
  width: 80%;
  background-size: 100%;
}

.idinput {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../assets/idicon.png");
  background-size: 5%;
  background-position-x: 20px;
  background-position-y: 15px;
}

.pwinput {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../assets/pwicon.png");
  background-size: 5%;
  background-position-x: 20px;
  background-position-y: 15px;
}

.loginbtn {
  background-image: url("../assets/Login_Btn_Login.png");
  background-size: 100%;
  width: 350px;
  height: 100px;
  cursor: pointer;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginbtn:hover {
  filter: brightness(0.8);
}

.whale {
  background-image: url("../assets/Login_Btn_Whale.png");
  width: 280px;
  height: 55px;
  background-size: 100%;
  position: absolute;
  bottom: 19%;
  left: 24%;
  cursor: pointer;
}

.whale:hover {
  filter: brightness(0.8);
}

.coolschool {
  width: 285px;
  height: 55px;
  background-color: white;
  cursor: pointer;
  position: absolute;
  bottom: 19%;
  left: 51%;
  border-radius: 10px;
  background-image: url("../assets/csc.png");
  background-size: 15%;
  background-position-x: 5%;
  background-position-y: 30%;
}

.coolschool:hover {
  filter: brightness(0.8);
}

input::-webkit-input-placeholder {
  color: #a4b5e5;
}

.swal2-popup {
  font-family: notosans;
  font-size: 1.6rem !important;
}

.maintenanceimg {
  width: 1000px;
  height: 490px;
  background-size: 100%;
  background-image: url("../assets/maintenanceimg.PNG");
}
</style>