<template>
  <div class="centered-container">
    <div class="centered-content">
      <v-progress-circular
        size="100"
        indeterminate
        color="gray"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { nonSaveCookie } from "@/store/cookie";

export default {
  async created() {
    this.code = this.$route.params.key;

    const res = await axios.get(
      `${process.env.VUE_APP_CONNECT}/users/auth/coolschool/token/${this.code}`
    );

    sessionStorage.setItem("info2", res.data.data);

    nonSaveCookie("info2", res.data.data);
    nonSaveCookie("cool", "true");

    setTimeout(() => {
      this.$router.push({ path: "/" });
    }, 500);
  },
};
</script>

<style>
.centered-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-content {
  position: relative;
}
</style>