<template>
  <div>
    <div v-if="device == 'Mobile'" style="overflow: hidden">
      <MobileComponent></MobileComponent>
    </div>
    <div v-if="device != 'Mobile'">
      <div
        v-show="splashmodal == true"
        id="splash"
        style="
          width: 100vw;
          height: 100vh;
          background-image: linear-gradient(to left, #6184ea, #2b4eb1);
          position: absolute;
          z-index: 8888888;
        "
      >
        <img src="./assets/splashtitle.png" alt="" class="homesplashlogo" />
      </div>
      <div
        v-show="loading == true"
        style="
          width: 100vw;
          height: 100vh;
          position: absolute;
          background-color: rgb(1, 1, 1, 0.5);
          z-index: 99900;
        "
      >
        <div
          style="
            width: 40%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        >
          <lottie :options="gameloading"></lottie>
        </div>
      </div>
      <div v-show="!$store.state.isLogin">
        <LoginComponent></LoginComponent>
      </div>

      <div
        v-show="$store.state.isLogin && loadCompleteFlag == true"
        style="width: 100vw; height: 100vh; overflow: hidden"
      >
        <iframe
          id="gamecontainer"
          :src="url"
          allow="camera *;microphone *;clipboard-read; clipboard-write"
          width="100%"
          height="100%"
          style="border: none"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import LoginComponent from "./LoginComponent.vue";
import FirstSettingComponent from "./panel/firstSettingComponent.vue";
import * as gameloading from "./assets/Ame_Loading.json";
import Lottie from "vue-lottie";
import MobileComponent from "./MobileComponent.vue";
import { deleteCookie, getSaveCookie } from "./store/cookie";

export default {
  name: "App",
  data() {
    return {
      test: sessionStorage.getItem("uid") || "",
      errortext: "",
      loadCompleteFlag: false,
      loading: false,
      gameloading: {
        animationData: gameloading,
        loop: true,
      },
      splashmodal: true,
    };
  },
  created() {
    this.allDelCookies();
    //sessionStorage.clear();

    if (this.$store.state.firstAccess == "true") {
      this.splashmodal = false;
    }

    const deviceType = this.detectDeviceType();

    if (deviceType == "Mobile") {
      this.device = deviceType;
    } else {
      this.device = deviceType;
      setTimeout(() => {
        this.splashmodal = false;
        this.saveCookie("first", true);
      }, 3000);
      sessionStorage.removeItem("load");
      sessionStorage.removeItem("info");
      document.cookie = `domain=${this.url}; Path=/; SameSite=None; Secure;`;
      window.addEventListener(
        "sessionStorageChange",
        this.handleSessionStorageChange
      );

      window.addEventListener("message", this.handleReload);

      window.addEventListener("loadComplete", this.handleLoadComplete);

      window.addEventListener("login", this.handleLogin);

      window.addEventListener("loading", this.handleLoading);
    }
  },
  beforeDestroy() {
    window.removeEventListener(
      "sessionStorageChange",
      this.handleSessionStorageChange
    );
  },
  components: {
    LoginComponent,
    FirstSettingComponent,
    lottie: Lottie,
    MobileComponent,
  },
  methods: {
    saveCookie(name, value) {
      var date = new Date();
      date.setTime(date.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);

      document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/;`;
    },
    detectDeviceType() {
      if (
        "ontouchstart" in window ||
        (navigator.pointerEnabled && navigator.maxTouchPoints > 0)
      ) {
        return "Mobile";
      } else {
        return "Desktop";
      }
    },
    handleSessionStorageChange(event) {
      if (event.detail.key == "uid") {
        this.$store.state.isLogin = event.detail.value;
      }
      if (event.detail == true) {
        this.loading = false;
        console.log("load complete");
      }
    },
    handleLoading(event) {
      this.loading = event.detail;
    },
    handleLoadComplete(event) {
      const res = event.detail;

      this.loadCompleteFlag = res;
      sessionStorage.setItem("load", true);
    },
    handleLogin(event) {
      //console.log("33", event);
      // const message = {
      //   type: "token",
      //   value: event.detail,
      // };
      //sessionStorage.setItem("info", event.detail);
      sessionStorage.clear();
      this.loading = false;
      //window.postMessage({ type: "token", value: event.detail });
      const iframe = document.getElementById("gamecontainer");
      iframe.contentWindow.postMessage(
        { type: "token", value: event.detail },
        this.url
      );
      //document.cookie = `token=${event.detail}`;
      // const iframe = document.getElementById("gamecontainer");
      // window.postMessage({ token: event.detail });
      // const child = document.getElementById("gamecontainer");

      // child.contentWindow.postMessage(message);
    },
    handleReload(event) {
      if (event.data.type) {
        if (event.data.type == "reloadPage") {
          // this.$store.state.isLogin = "";
          // this.loadCompleteFlag = false;
          window.location.reload();
        }
      }
    },
    // 쿠키 전체 삭제하기
    allDelCookies() {
      const cookies = document.cookie.split("; "); // 배열로 반환
      const expiration = "Sat, 01 Jan 1972 00:00:00 GMT";

      // 반목문 순회하면서 쿠키 전체 삭제
      if (!document.cookie) {
        return;
      } else {
        for (let i = 0; i < cookies.length; i++) {
          if (cookies[i].split("=") == "first") {
            continue;
          }
          document.cookie =
            cookies[i].split("=")[0] + "=; expires=" + expiration;
        }
      }
    },
    handleWhaleLogin(event) {
      this.$store.state.isLogin = true;
      this.allDelCookies();
      const iframe = document.getElementById("gamecontainer");
      iframe.contentWindow.postMessage(
        { type: "token", value: event.detail },
        this.url
      );
    },
  },
  computed: {
    url() {
      return `https://argame3.blob.core.windows.net/webgl-deploy-nia/index.html?${uuidv4()}`;
    },
  },
};
</script>

<style>
/* .modaltitle {
  background-image: url("./assets/modaltitlepanel.png");
  background-size: 100%;
  width: 800px;
  height: 160px;
  position: absolute;
  margin-top: -50px;
  margin-left: 90px;
}

.modalcontent {
  background-image: url("./assets/modalPopup.png");
  background-size: 100%;
  width: 900px;
  height: 570px;
  min-width: 1000px;
} */
@font-face {
  font-family: notosans;
  src: url("./assets/font/NotoSansKR-Bold.otf");
}

@font-face {
  font-family: cookiebold;
  src: url("./assets/font/CookieRun Bold.otf");
}

/* .splash{
  animation: ;
} */

.homesplashlogo {
  width: 45%;
  position: absolute;
  top: 23%;
  left: 28%;
  transform: translate(-50%, -50%);
  animation: shrink 2s forwards, bounce 0.5s 2s forwards;
}

@keyframes shrink {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
}
</style>