<template>
  <div>
    <div style="position: absolute; margin-top: 300px; margin-left: 210px">
      <v-progress-circular
        size="80"
        indeterminate
        color="gray"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { nonSaveCookie } from "../store/cookie";

export default {
  data() {
    return {
      code: "",
    };
  },
  async created() {
    this.code = this.$route.params.code;

    const client_id = process.env.VUE_APP_NAVER_CLIENT_ID;
    const client_secret = process.env.VUE_APP_NAVER_CLIENT_SECRET;

    const data = {
      grant_type: `authorization_code`,
      code: this.code,
      client_id,
      client_secret,
    };

    const result = await axios.post(
      `https://auth.whalespace.io/oauth2/v1.1/token`,
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // Content-Type 설정
        },
      }
    );

    const res = await axios.post(
      `${process.env.VUE_APP_CONNECT}/users/token/whale`,
      {
        code: result.data.access_token,
        schoolCode: "",
      },
      { credentials: true }
    );

    sessionStorage.setItem("info2", res.data.data);
    nonSaveCookie("info2", res.data.data);

    window.dispatchEvent(
      new CustomEvent("whalelogincomplete", {
        detail: true,
      })
    );
  },
};
</script>

<style>
</style>