<template>
  <div class="maindiv">
    <div class="logodiv"></div>

    <div class="characterdiv">
      <div class="vestadiv"></div>
      <div class="eggdiv"></div>
      <div class="tomydiv"></div>
    </div>

    <div class="btndiv">
      <img @click="goapp()" class="appbtn" src="./assets/Btngo.png" alt="" />
      <div class="btndiv2">
        <img
          @click="gohomepage()"
          src="./assets/Btn_Home.png"
          alt=""
          class="homebtn"
        />
        <img
          @click="golms()"
          src="./assets/Btn_LMS.png"
          alt=""
          class="lmsbtn"
        />
        <img
          @click="goguide()"
          src="./assets/Btn_Guide.png"
          alt=""
          class="guidebtn"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("resize", this.adjustOrientation);
    window.addEventListener("load", this.adjustOrientation);
  },
  methods: {
    adjustOrientation() {
      const div = document.getElementsByClassName("maindiv");
      const container = div[0];
      if (window.innerHeight < window.innerWidth) {
        // 가로 모드일 때
        container.style.width = "100vh";
        container.style.height = "100vw";
        container.style.transform = "rotate(-90deg) translate(-100%)";
        container.style.transformOrigin = "left top";
      } else {
        // 세로 모드일 때
        container.style.width = "100vw";
        container.style.height = "100vh";
        container.style.transform = "none";
      }
    },
    goapp() {
      var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

      if (varUA.indexOf("android") > -1) {
        //안드로이드
        window.open(
          "https://play.google.com/store/apps/details?id=com.dnsoft.argongthree"
        );
      } else if (
        varUA.indexOf("iphone") > -1 ||
        varUA.indexOf("ipad") > -1 ||
        varUA.indexOf("ipod") > -1
      ) {
        //IOS
        window.open(
          "https://apps.apple.com/kr/app/%EC%95%8C%EA%B3%B5-english-planet/id1670252817"
        );
      } else {
        return;
      }
    },
    gohomepage() {
      window.open("https://www.argong.ai");
    },
    golms() {
      window.open("https://manager.argong.ai");
    },
    goguide() {
      window.open("https://bit.ly/3PkiTh3");
    },
  },
};
</script>

<style scoped>
.maindiv {
  background-image: url("./assets/Mobilebg.png");
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logodiv {
  background-image: url("./assets/splashtitle.png");
  background-size: 100%;
  width: 70%;
  height: 25%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.characterdiv {
  position: absolute;
  top: 70%;
  left: 54%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 70%;
}

.vestadiv {
  float: left;
  background-size: 100%;
  background-image: url("../public/베스타.png");
  width: 35%;
  height: 50%;
}

.eggdiv {
  float: left;
  background-size: 100%;
  background-image: url("../public/반숙이.png");
  width: 20%;
  height: 50%;
  margin-left: 10px;
}

.tomydiv {
  float: left;
  background-size: 100%;
  background-image: url("../public/토미.png");
  width: 30%;
  height: 50%;
  margin-left: 20px;
}

.btndiv {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 20%;
}

.btndiv2 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 80%;
  left: 56%;
  transform: translate(-50%, -50%);
}

.homebtn {
  width: 30%;
  float: left;
}

.lmsbtn {
  width: 30%;
  float: left;
}

.guidebtn {
  width: 30%;
  float: left;
}

.appbtn {
  width: 70%;
  position: absolute;
  left: 50%;
  top: -2%;
  transform: translate(-50%, -50%);
}

.homebtn:hover {
  filter: brightness(0.7);
}

.lmsbtn:hover {
  filter: brightness(0.7);
}

.guidebtn:hover {
  filter: brightness(0.7);
}

.appbtn:hover {
  filter: brightness(0.7);
}
</style>